<template>
  <div class="pb-5" data-wizard-type="step-content">
    <b-row>
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ $t("SIGNUP.ACCOUNT_ADMINISTRATOR") }}
          </strong>
        </h5>
        <i>
          {{ $t("SIGNUP.DESCRIPTION_ACCOUNT_ADMINISTRATOR") }}
        </i>
      </b-col>
      <!-- FIRST NAME -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-text-field
          v-model="info.name"
          label="First Name"
          :rules="validations.required"
          required
          outlined
          dense
        />
      </b-col>

      <!-- LAST NAME -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-text-field
          v-model="info.lastname"
          label="Last Name"
          :rules="validations.required"
          required
          outlined
          dense
        />
      </b-col>

      <!-- POSITION -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-text-field
          v-model="info.companyPosition"
          label="Position"
          :rules="validations.required"
          required
          outlined
          dense
        />
      </b-col>

      <!-- email -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-text-field
          v-model="info.email"
          label="Email"
          :rules="validations.email"
          outlined
          dense
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Rules from "@/core/services/utils/validations";
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      admin: {
        name: null,
        lastname: null,
        companyPosition: null,
        email: null,
      },
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  computed: {
    info() {
      return this.admin;
    },
  },
  watch: {
    data: {
      handler: function(newVal) {
        const { user } = newVal;
        this.admin.name = user?.name;
        this.admin.lastname = user?.lastname;
        this.admin.companyPosition = user?.companyPosition;
        this.admin.email = user?.email;
      },
    },
    admin: {
      handler: function() {
        const data = {
          ...this.admin,
          inValid: false,
        };

        if (
          data.name === null ||
          data.lastname === null ||
          data.companyPosition === null ||
          data.email === null
        ) {
          data.inValid = true;
        }

        this.$emit("update:admin-info", data);
      },
      deep: true,
    },
  },
};
</script>
