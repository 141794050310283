import ApiService from "@/core/services/api.service";

const path = "api/v1/register-wizard";
const path_get = "api/v1/registration-form";

export const SaveInfo = info => {
  return new Promise((resolve, reject) => {
    ApiService.post(path, info)
      .then(response => {
        resolve(response);
      })
      .catch(response => {
        reject(response);
      });
  });
};

export const GetInfo = token => {
  return new Promise((resolve, reject) => {
    ApiService.get(`${path_get}/${token}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
