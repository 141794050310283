<template>
  <div
    class="page-content-wizard"
    :style="{ backgroundImage: `url(${backgroundImage})` }"
  >
    <div class="content-logo">
      <div>
        <img
          src="media/logos/logo-ynsitu-login.png"
          class="h-25"
          alt="background"
        />
      </div>
      <div class="flex-column-fluid d-flex flex-column justify-content-center">
        <h3 class="mt-10 mb-1 text-white">
          Register
        </h3>
      </div>
    </div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <!--begin: Wizard-->
        <div
          class="wizard wizard-2"
          id="kt_wizard_v2"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!-- MAIN -->
          <Main :type="info" />
          <!--begin: Wizard Body -->
          <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
            <!--begin: Wizard Form-->
            <div class="row">
              <div class="offset-xxl-2 col-xxl-8">
                <v-form ref="form">
                  <!--begin: Wizard Step 1-->
                  <PersonalData
                    :personal-info.sync="personalInformation"
                    :data="userInfo"
                  />

                  <!--begin: Wizard Step 2-->
                  <CompanyData
                    :company-info.sync="companyInformation"
                    :data="userInfo"
                    v-if="businessPrivate === 1"
                  />

                  <!--begin: Wizard Step 3-->
                  <AdminAccount
                    :admin-info.sync="adminInformation"
                    :data="userInfo"
                  />

                  <!--begin: Wizard Step 4-->
                  <GeneralConditions
                    :general-info.sync="generalInformation"
                    :conditions="conditions"
                    :commissions="CompanyCommission"
                    :companyId="companyId"
                  />

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                        @click="prevStep"
                      >
                        Previous
                      </button>
                    </div>
                    <div>
                      <button
                        @click="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        :disabled="isSubmit || isDisabledSubmitButton"
                        data-wizard-type="action-submit"
                      >
                        Submit
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                        :disabled="isDisabled"
                        @click="nextStep"
                      >
                        Next Step
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </v-form>
              </div>
              <!--end: Wizard-->
            </div>
          </div>
          <!--end: Wizard Body -->
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<script>
import PersonalData from "./component/PersonalData";
import CompanyData from "./component/CompanyData";
import AdminAccount from "./component/AdminAccount";
import GeneralConditions from "./component/GeneralConditions";
import Main from "./component/Main";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import { SaveInfo, GetInfo } from "@/core/services/utils/wizardService";
import { mapGetters } from "vuex";
import { GET_COMPANY_COMMISSION } from "@/core/services/store/general/company.module";

export default {
  components: {
    PersonalData,
    CompanyData,
    AdminAccount,
    GeneralConditions,
    Main,
  },
  data() {
    return {
      step: 1,
      personalInformation: null,
      companyInformation: null,
      adminInformation: null,
      generalInformation: null,
      userInfo: null,
      businessPrivate: null,
      conditionId: null,
      conditions: null,
      isDisabledSubmitButton: false,
      companyId: null,
    };
  },
  methods: {
    /**
     * update step
     * @method nextStep
     */
    nextStep() {
      this.step += 1;
    },

    /**
     * update step
     * @method prevStep
     */
    prevStep() {
      this.step -= 1;
    },

    /**
     * Create new user
     * @method submit
     * @returns 'submit' object whit data
     */
    async submit(e) {
      e.preventDefault();
      this.isDisabledSubmitButton = true;
      const dataNew = {
        person: { ...this.personalInformation },
        admin: { ...this.adminInformation },
        general: { ...this.generalInformation },
        conditionId: this.conditionId,
      };

      if (this.businessPrivate === 1) {
        dataNew.company = {
          ...this.companyInformation,
          company_id: this.userInfo.company.companyId,
        };
      } else {
        dataNew.company = {
          ...this.companyInformation,
          company_id: this.userInfo.company.companyId,
        };
      }

      await SaveInfo(dataNew)
        .then(data => {
          if (data.data) {
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });

            setTimeout(() => {
              this.$router.push("/created");
            }, 2000);
          }
        })
        .catch(() => {
          this.isDisabledSubmitButton = false;
        });
    },
  },
  async created() {
    const { token } = this.$route.params;

    const data = await GetInfo(token);
    if (data.message === "INVALID_TOKEN") {
      this.$bvToast.toast(this.$t("GENERAL.INVALID_TOKEN"), {
        title: "Error",
        variant: "danger",
        solid: true,
      });
    }
    this.userInfo = data;
    this.businessPrivate = data.company.bussinessPrivate;
    this.conditionId = data.condition.conditionId;
    this.conditions = data.condition.conditions;
    this.companyId = data.company.companyId;
    const json = {
      companyId: data.company.companyId,
      auth: false,
    };

    await this.$store.dispatch(GET_COMPANY_COMMISSION, json);
  },
  updated() {
    const wizard = new KTWizard("kt_wizard_v2", {
      startStep: 1,
      clickableSteps: false,
    });

    wizard.on("beforeNext", function() {});

    wizard.on("change", function() {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  computed: {
    ...mapGetters(["CompanyCommission"]),
    isDisabled() {
      if (this.step === 1) {
        return this.personalInformation
          ? this.personalInformation.inValid
          : true;
      } else if (this.step === 2) {
        if (this.businessPrivate === 2) {
          return this.adminInformation ? this.adminInformation.inValid : true;
        } else {
          return this.companyInformation
            ? this.companyInformation.inValid
            : true;
        }
      } else if (this.step === 3) {
        return this.adminInformation ? this.adminInformation.inValid : true;
      }
      return false;
    },
    isSubmit() {
      if (this.generalInformation) {
        const { conditions, commissions } = this.generalInformation;
        return !(conditions && commissions);
      }
      return true;
    },
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-2.jpg";
    },
    info() {
      return this.businessPrivate;
    },
  },
};
</script>
