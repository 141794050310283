<template>
  <!--begin: Wizard Nav -->
  <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
    <div class="wizard-steps">
      <div
        class="wizard-step"
        data-wizard-type="step"
        data-wizard-state="current"
      >
        <div class="wizard-wrapper">
          <div class="wizard-number">
            <font-awesome-icon icon="angle-right" />
          </div>
          <div class="wizard-label">
            <h3 class="wizard-title">
              Personal data
            </h3>
            <div class="wizard-desc">
              of the Company's representative
            </div>
          </div>
        </div>
      </div>
      <div class="wizard-step" data-wizard-type="step" v-if="type === 1">
        <div class="wizard-wrapper">
          <div class="wizard-number">
            <font-awesome-icon icon="angle-right" />
          </div>
          <div class="wizard-label">
            <h3 class="wizard-title">
              Company data
            </h3>
            <div class="wizard-desc">
              check the identification data
            </div>
          </div>
        </div>
      </div>
      <div class="wizard-step" href="#" data-wizard-type="step">
        <div class="wizard-wrapper">
          <div class="wizard-number">
            <font-awesome-icon icon="angle-right" />
          </div>
          <div class="wizard-label">
            <h3 class="wizard-title"></h3>
            <div class="wizard-desc">
              {{ $t("SIGNUP.ACCOUNT_ADMINISTRATOR") }}
            </div>
          </div>
        </div>
      </div>
      <div class="wizard-step" data-wizard-type="step">
        <div class="wizard-wrapper">
          <div class="wizard-number">
            <font-awesome-icon icon="angle-right" />
          </div>
          <div class="wizard-label">
            <h3 class="wizard-title">
              General conditions
            </h3>
            <div class="wizard-desc">
              read and accept the conditions
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end: Wizard Nav -->
</template>
<script>
export default {
  props: {
    type: Number,
  },
};
</script>
