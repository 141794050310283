<template>
  <div class="pb-5" data-wizard-type="step-content">
    <b-row>
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong> {{ $t("SETTINGS.USER.BUSSINESS.TITLE2") }} </strong>
        </h5>
      </b-col>
      <b-col lg="12" md="12" sm="12" cols="12">
        <div class="d-inline-block cursor-pointer" @click="dialog = true">
          <v-checkbox
            v-model="info.conditions"
            hide-details
            label=""
            disabled
          />
        </div>
        <div class="d-inline-block text-general-conditions-check-2">
          <span
            class="general-conditions-check cursor-pointer"
            @click="dialog = true"
          >
            {{ $t("SETTINGS.USER.BUSSINESS.ACCEPT") }}
          </span>
        </div>
      </b-col>
      <!-- COMMISSIONS -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <div
          class="d-inline-block cursor-pointer"
          @click="dialogCommissions = true"
        >
          <v-checkbox
            v-model="info.commissions"
            hide-details
            label=""
            disabled
          />
        </div>
        <div class="d-inline-block text-general-conditions-check-2">
          <span
            class="general-conditions-check cursor-pointer"
            @click="dialogCommissions = true"
          >
            {{ $t("COMMISSIONS.TITLE") }}
          </span>
        </div>
      </b-col>
    </b-row>

    <!-- MODAL GENERAL CONDITIONS -->
    <b-row justify="center">
      <v-dialog v-model="dialog" scrollable max-width="1000px">
        <v-card>
          <v-card-title>
            {{ $t("SETTINGS.USER.BUSSINESS.TITLE2") }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 400px;">
            <Terms :conditions="conditions" />
            <div class="d-flex justify-content-end">
              <v-btn
                depressed
                color="primary"
                class="float-right d-inline-block mr-2"
                @click="accept()"
                >{{ $t("GENERAL.ACCEPT") }}</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </b-row>
    <!-- MODAL COMMISSIONS -->
    <b-row justify="center">
      <v-dialog
        v-model="dialogCommissions"
        scrollable
        max-width="960px"
        persistent
      >
        <v-card>
          <v-card-title>
            {{ $t("COMMISSIONS.TITLE") }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text
            style="height: 400px;"
            v-if="Object.keys(commissions).length > 0"
          >
            <Commissions :data="commissions" />
            <v-divider></v-divider>
            <div class="d-flex justify-content-end">
              <v-btn
                depressed
                color="primary"
                class="float-right d-inline-block mr-2"
                @click="accept2()"
                >{{ $t("GENERAL.ACCEPT") }}</v-btn
              >
            </div>
          </v-card-text>
          <v-card-text v-else>
            <TabsCommission
              ref="tabsCommission"
              :isShowSave="true"
              :companyId="companyId"
              :callbackAccept="() => accept2()"
            ></TabsCommission>
          </v-card-text>
        </v-card>
      </v-dialog>
    </b-row>
  </div>
</template>

<script>
import Rules from "@/core/services/utils/validations";
import Terms from "@/view/pages/settings/Terms";
import Commissions from "@/view/content/components/Commissions";
import TabsCommission from "@/view/pages/superadmin/company/components/TabsCommission";
import { mapGetters } from "vuex";
export default {
  components: {
    Terms,
    Commissions,
    TabsCommission,
  },
  props: {
    conditions: {
      type: String,
      default: null,
    },
    commissions: Object,
    companyId: { type: Number, default: 0 },
  },
  data() {
    return {
      dialog: false,
      dialogCommissions: false,
      general: {
        conditions: false,
        commissions: false,
      },
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  methods: {
    accept() {
      this.general.conditions = true;
      this.dialog = false;
    },
    accept2() {
      this.general.commissions = true;
      this.dialogCommissions = false;
    },
  },
  computed: {
    ...mapGetters(["company"]),
    info() {
      return this.general;
    },
  },
  watch: {
    general: {
      handler: function() {
        const data = {
          ...this.general,
        };
        this.$emit("update:general-info", data);
      },
      deep: true,
    },
  },
};
</script>
