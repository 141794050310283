<template>
  <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
    <b-row>
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong> Personal data </strong>
        </h5>
      </b-col>
      <!-- FIRST NAME -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-text-field
          v-model="info.contract_name"
          label="First Name"
          outlined
          required
          dense
        />
      </b-col>

      <!-- LAST NAME -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-text-field
          v-model="info.contract_surname"
          label="Last Name"
          outlined
          required
          dense
        />
      </b-col>

      <!-- Email -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-text-field
          v-model="info.contract_email"
          label="School Email"
          outlined
          required
          dense
        />
      </b-col>

      <!-- VAT ID / Company Registration / ID -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-text-field
          v-model="info.vat"
          label="VAT ID / Company Registration / ID"
          outlined
          required
          dense
        />
      </b-col>

      <!-- PHONE -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-text-field
          v-model="info.contract_phone"
          label="Phone"
          outlined
          required
          dense
        />
      </b-col>

      <!-- WEBSITE -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-text-field
          v-model="info.websiteUrl"
          label="Website"
          outlined
          dense
        />
      </b-col>

      <!-- POSITION -->
      <b-col lg="12" md="12" sm="12" cols="12" v-if="bussinessPrivate === 1">
        <v-text-field
          v-model="info.contract_position"
          label="Position"
          :rules="validations.required"
          outlined
          required
          dense
        />
      </b-col>

      <!-- LEGAL EMAIL -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-text-field
          v-model="info.contract_passport"
          label="Confirm Your Business Email"
          :rules="validations.required"
          required
          outlined
          dense
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Rules from "@/core/services/utils/validations";
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      form: {
        contract_name: null,
        contract_surname: null,
        contract_position: null,
        contract_passport: null,
        vat: null,
        websiteUrl: null,
      },
      companyInfo: {
        contract_email: null,
        contract_phone: null,
        websiteUrl: null,
      },
      bussinessPrivate: null,
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  computed: {
    info() {
      return this.form;
    },
  },
  watch: {
    data: {
      handler: function (newVal) {
        const { company, user } = newVal;
        if (this.data.bussinessPrivate === 2) {
          this.form.contract_name = user?.name;
          this.form.contract_surname = user?.lastname;
          this.form.contract_position = user?.companyPosition;
          this.form.contract_passport = user?.contract_passport;
          this.form.contract_email = user?.contract_email;
          this.form.vat = user?.vat;
          this.form.websiteUrl = user?.websiteUrl;
          this.form.contract_phone = user?.contract_phone;
        } else {
          this.form.contract_name = company?.contract_name;
          this.form.contract_surname = company?.contract_surname;
          this.form.contract_position = company?.contract_position;
          this.form.contract_passport = company?.contract_passport;
          this.form.contract_email = company?.contract_email;
          this.form.contract_phone = company?.contract_phone;
          this.form.vat = company?.vat;
          this.form.websiteUrl = company?.websiteUrl;
        }
        this.bussinessPrivate = company?.bussinessPrivate;
      },
    },
    form: {
      handler: function () {
        const data = {
          ...this.form,
          inValid: false,
        };

        if (this.bussinessPrivate === 2) {
          if (
            data.contract_name === null ||
            data.contract_surname === null ||
            data.contract_passport === null
          ) {
            data.inValid = true;
          }
        } else {
          if (
            data.contract_name === null ||
            data.contract_surname === null ||
            data.contract_position === null ||
            data.contract_passport === null
          ) {
            data.inValid = true;
          }
        }

        this.$emit("update:personal-info", data);
      },
      deep: true,
    },
  },
};
</script>
