<template>
  <div class="pb-5" data-wizard-type="step-content">
    <b-row>
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong> Company data </strong>
        </h5>
      </b-col>
      <!-- Legal Name -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-text-field
          v-model="info.legal_name"
          label="Legal Name"
          :rules="validations.required"
          outlined
          dense
        />
      </b-col>

      <!-- Commercial Name -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-text-field
          v-model="info.commercial_name"
          label="Commercial Name"
          :rules="validations.required"
          required
          outlined
          dense
        />
      </b-col>

      <!-- VAT ID / Company Registration / ID -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-text-field
          v-model="info.vat"
          label="VAT ID / Company Registration / ID"
          :rules="validations.required"
          outlined
          dense
        />
      </b-col>

      <!-- Address -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <VueGooglePlaces
          v-model="info.address"
          :api-key="key"
          types="address"
          @placechanged="getAddressData"
          class="without-placeholder"
        >
          <v-text-field
            v-model="info.address"
            label="Address"
            :rules="[
              validations.addressWizard(info.address, info.addressComponent),
            ]"
            required
            outlined
            dense
          />
        </VueGooglePlaces>
      </b-col>

      <!-- email -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-text-field
          v-model="info.email"
          label="Email"
          :rules="validations.email"
          outlined
          dense
        />
      </b-col>

      <!-- Website -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-text-field
          v-model="info.websiteUrl"
          label="Website"
          :rules="validations.required"
          required
          outlined
          dense
        />
      </b-col>

      <!-- Phone -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-text-field
          v-model="info.phone"
          label="Phone"
          type="number"
          :rules="validations.interger"
          required
          outlined
          dense
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Rules from "@/core/services/utils/validations";
import { VueGooglePlaces } from "vue-google-places";
export default {
  components: {
    VueGooglePlaces,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      key: process.env.VUE_APP_PLACE_KEY,
      company: {
        legal_name: null,
        commercial_name: null,
        vat: null,
        address: null,
        addressComponent: null,
        email: null,
        websiteUrl: null,
        phone: null,
      },
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  methods: {
    /**
     * get info google place for addressComponent
     * @method getAddressData
     * @returns 'addressComponent' object whit data
     */
    getAddressData(addressData) {
      this.company.addressComponent = addressData;
    },
  },
  computed: {
    info() {
      return this.company;
    },
  },
  mounted() {
    const { company } = this.data;
    this.company.legal_name = company.legal_name;
    this.company.commercial_name = company.commercial_name;
    this.company.vat = company.vat;
    this.company.address = company.address;
    this.company.email = company.contract_email;
    this.company.websiteUrl = company.websiteUrl;
    this.company.phone = company.contract_phone;
    this.bussinessPrivate = company.bussinessPrivate;
  },
  watch: {
    data: {
      handler: function(newVal) {
        const { company } = newVal;
        this.company.legal_name = company.legal_name;
        this.company.commercial_name = company.commercial_name;
        this.company.vat = company.vat;
        this.company.address = company.address;
        this.company.email = company.contract_email;
        this.company.websiteUrl = company.websiteUrl;
        this.company.phone = company.contract_phone;
        this.bussinessPrivate = company.bussinessPrivate;
      },
    },
    company: {
      handler: function() {
        const data = {
          ...this.company,
          inValid: false,
        };

        if (
          data.legal_name === null ||
          data.commercial_name === null ||
          data.vat === null ||
          data.address === null ||
          data.email === null ||
          data.websiteUrl === null ||
          data.phone === null
        ) {
          data.inValid = true;
        }

        this.$emit("update:company-info", data);
      },
      deep: true,
    },
  },
};
</script>
